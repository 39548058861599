import { assertUnreachable } from '@/lib/assertUnreachable';
import * as Assets from './Icon.assets';
import { IconName, ReactSvgType } from './Icon.types';

export function findIconByName(iconName: IconName): ReactSvgType | null {
  switch (iconName) {
    case 'arrow-down': {
      return Assets.ArrowDownIcon;
    }
    case 'arrow-up': {
      return Assets.ArrowUpIcon;
    }
    case 'answer-add': {
      return Assets.AnswerAdd;
    }
    case 'answer-add-create': {
      return Assets.AnswerAddCreate;
    }
    case 'excel': {
      return Assets.Excel;
    }
    case 'excel-gray': {
      return Assets.ExcelGray;
    }
    case 'tie': {
      return Assets.Tie;
    }
    case 'info': {
      return Assets.Info;
    }
    case 'close': {
      return Assets.Close;
    }
    case 'arrow-left': {
      return Assets.ArrowLeftIcon;
    }
    case 'arrow-left-01': {
      return Assets.ArrowLeftIcon01;
    }
    case 'arrow-right': {
      return Assets.ArrowRightIcon;
    }
    case 'arrow-right-01': {
      return Assets.ArrowRightIcon01;
    }
    case 'spinner': {
      return Assets.Spinner;
    }
    case 'restore': {
      return Assets.Restore;
    }
    case 'input-file': {
      return Assets.InputFile;
    }
    case 'help': {
      return Assets.Help;
    }
    case 'search': {
      return Assets.Search;
    }
    case 'share': {
      return Assets.Share;
    }
    case 'chat': {
      return Assets.Chat;
    }
    case 'select': {
      return Assets.Select;
    }
    case 'filter': {
      return Assets.Filter;
    }
    case 'copy': {
      return Assets.Copy;
    }
    case 'call-end': {
      return Assets.CallEnd;
    }
    case 'marker-pin': {
      return Assets.MarkerPin;
    }
    case 'plus': {
      return Assets.Plus;
    }
    case 'edit': {
      return Assets.Edit;
    }
    case 'trash': {
      return Assets.Trash;
    }
    case 'calendar': {
      return Assets.Calendar;
    }
    case 'clock-fast-forward': {
      return Assets.ClockFastForward;
    }
    case 'globe': {
      return Assets.Globe;
    }
    case 'mail': {
      return Assets.Mail;
    }
    case 'contact-add': {
      return Assets.ContactAdd;
    }
    case 'contact-added': {
      return Assets.ContactAdded;
    }
    case 'ok': {
      return Assets.Ok;
    }
    case 'mute': {
      return Assets.Mute;
    }
    case 'unmute': {
      return Assets.Unmute;
    }
    case 'ETA': {
      return Assets.ETA;
    }
    case 'prepayment': {
      return Assets.Prepayment;
    }
    case 'delivery': {
      return Assets.Delivery;
    }
    case 'success': {
      return Assets.Success;
    }
    case 'link-outside-round': {
      return Assets.LinkOutsideRound;
    }
    case 'mark': {
      return Assets.Mark;
    }
    case 'phone': {
      return Assets.Phone;
    }
    case 'fax': {
      return Assets.Fax;
    }
    case 'file': {
      return Assets.File;
    }
    case 'download': {
      return Assets.Download;
    }
    case 'download-02': {
      return Assets.Download02;
    }
    case 'view': {
      return Assets.View;
    }
    case 'library': {
      return Assets.Library;
    }
    case 'locked': {
      return Assets.Locked;
    }
    case 'unlocked': {
      return Assets.Unlocked;
    }
    case 'person': {
      return Assets.Person;
    }
    case 'reset': {
      return Assets.Reset;
    }
    case 'call-failed':
      return Assets.CallFailed;
    case 'comparison':
      return Assets.Comparison;
    case 'eye':
      return Assets.Eye;
    case 'eye-off':
      return Assets.EyeOff;
    case 'vacation':
      return Assets.Vacation;
    case 'dnd':
      return Assets.Dnd;
    case 'sick':
      return Assets.Sick;
    case 'no-status':
      return Assets.NoStatus;
    case 'away':
      return Assets.Away;
    case 'meeting':
      return Assets.Meeting;
    case 'file-text':
      return Assets.FileText;
    case 'file-check':
      return Assets.FileCheck;
    case 'file-x':
      return Assets.FileX;
    case 'arrow-right-dash':
      return Assets.ArrowRightDash;
    case 'arrow-down-select':
      return Assets.ArrowDownSelect;
    case 'arrow-narrow-down-right':
      return Assets.ArrowNarrowDownRight;
    case 'alert-triangle':
      return Assets.AlertTriangle;
    case 'alert-fill':
      return Assets.AlertFill;
    case 'printer':
      return Assets.Printer;
    case 'arrow-up-02':
      return Assets.ArrowUp02;
    case 'coins':
      return Assets.Coins;
    case 'coins-stacked-02':
      return Assets.CoinsStacked02;
    case 'coins-stacked-03':
      return Assets.CoinsStacked03;
    case 'sort-asc':
      return Assets.SortAsc;
    case 'sort-desc':
      return Assets.SortDesc;
    case 'archive':
      return Assets.Archive;
    case 'archive-in':
      return Assets.ArchiveIn;
    case 'archive-out':
      return Assets.ArchiveOut;
    case 'close-01':
      return Assets.Close01;
    case 'sort-default':
      return Assets.SortDefault;
    case 'favorite':
      return Assets.Favorite;
    case 'arrow-right-02':
      return Assets.ArrowRight02;
    case 'arrow-left-02':
      return Assets.ArrowLeft02;
    case 'key':
      return Assets.Key;
    case 'users':
      return Assets.Users;
    case 'users-lupa':
      return Assets.UsersLupa;
    case 'publish':
      return Assets.Publish;
    case 'exit':
      return Assets.Exit;
    case 'add':
      return Assets.Add;
    case 'submenu':
      return Assets.SubMenu;
    case 'admin':
      return Assets.Admin;
    case 'contact-delete':
      return Assets.ContactDelete;
    case 'alarm':
      return Assets.Alarm;
    case 'auction':
      return Assets.Auction;
    case 'reduction':
      return Assets.Reduction;
    case 'folder':
      return Assets.Folder;
    case 'position-delivery':
      return Assets.PositionDelivery;
    case 'subtract':
      return Assets.Subtract;
    case 'link':
      return Assets.Link;
    case 'download-01':
      return Assets.download01;
    case 'upload-04':
      return Assets.upload04;
    case 'question-fill':
      return Assets.questionFill;
    case 'info-fill':
      return Assets.infoFill;
    case 'cancel':
      return Assets.Cancel;
    case 'attach':
      return Assets.Attach;
    case 'bell-off':
      return Assets.BellOff;
    case 'file-block':
      return Assets.FileBlocked;
    case 'check-circle-broken':
      return Assets.Confirmation;
    case 'bookmark':
      return Assets.Bookmark;
    case 'bookmark-fill':
      return Assets.BookmarkFill;
    case 'resend':
      return Assets.Resend;
    case 'mail-send':
      return Assets.MailSend;
    case 'import':
      return Assets.Import;
    case 'rating':
      return Assets.Rating;
    case 'trust':
      return Assets.Trust;
    case 'copy-solid':
      return Assets.CopySolid;
    case 'home':
      return Assets.Home;
    case 'users-slash':
      return Assets.UsersSlash;
    case 'mail-filled':
      return Assets.MailFilled;
    case 'add-qualification':
      return Assets.AddQualification;
    case 'subscribe':
      return Assets.Subscribe;
    case 'unsubscribe':
      return Assets.Unsubscribe;
    case 'qualification':
      return Assets.Qualification;
    case 'file-02':
      return Assets.File02;
    case 'notification-text':
      return Assets.NotificationText;
    case 'phone-call-01':
      return Assets.PhoneCall01;
    case 'file-03':
      return Assets.File03;
    case 'currency-ruble-circle':
      return Assets.Ruble;
    case 'horn':
      return Assets.Horn;
    case 'EUR':
      return Assets.Euro;
    case 'USD':
      return Assets.Dollar;
    case 'lock-close-02':
      return Assets.LockClose02;
    case 'circle-01':
      return Assets.Circle01;
    case 'add-01':
      return Assets.Add01;
    case 'min':
      return Assets.Min;
    case 'ph-user-fill':
      return Assets.PhUserFill;
    case 'layers-three-02':
      return Assets.LayersThree02;
    case 'users-filled':
      return Assets.UsersFilled;
    case 'branch':
      return Assets.Branch;
    case 'columns-03':
      return Assets.Columns03;
    default: {
      return assertUnreachable(iconName);
    }
  }
}
