import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import assert from 'assert';
import { difference } from 'ramda';
import type { LinkPermissionsStrategy } from '@/modules/permissions/strategies';
import { CompanyRoles } from '@/components/CompanyRoles';
import { AppSection } from '@/constants/section';
import { ModuleName } from '@/constants/module';
import { usePermissions } from '@/modules/permissions';
import { getPlainCurrentUser } from '@/storeGetters';
import { keys } from '@/utils';
import { getActiveModule } from '@/storeGetters/storeGetters';

export type Sections = Record<AppSection, boolean>;

interface GetAllowedSectionsProps {
  module?: ModuleName;
  companyRole?: CompanyRoles;
  allRoles?: boolean;
}

class ConditionBuilder {
  private activeModule: ModuleName | undefined;

  private companyRoles: CompanyRoles[] | undefined;

  private permissions: LinkPermissionsStrategy | undefined;

  private allRoles = false;

  setActiveModule(activeModule: ModuleName) {
    this.activeModule = activeModule;

    return this;
  }

  setCompanyRoles(companyRoles: CompanyRoles[]) {
    this.companyRoles = companyRoles;

    return this;
  }

  setPermissions(permissions: LinkPermissionsStrategy) {
    this.permissions = permissions;

    return this;
  }

  setAllRoles(allRoles: boolean) {
    this.allRoles = allRoles;

    return this;
  }

  build() {
    const { activeModule, companyRoles, permissions, allRoles } = this;

    assert(activeModule, 'ActiveModule must be present');
    assert(companyRoles, 'CompanyRoles must be present');
    assert(permissions, 'Permissions must be present');

    return {
      activeModule,
      companyRoles: allRoles ? Object.values(CompanyRoles) : companyRoles,
      permissions
    };
  }
}

function sectionsToAllowedSectionsList(sectionsObj: Sections): AppSection[] {
  return keys(sectionsObj).filter((sectionName) => sectionsObj[sectionName]);
}

export function useAppSections() {
  const permissions = usePermissions();
  const { currentCompanyRole } = useSelector(getPlainCurrentUser);

  const currentActiveModule = useSelector(getActiveModule);

  const getAllowedSections = useCallback(
    (options: GetAllowedSectionsProps = {}) => {
      const { module, companyRole, allRoles = false } = options;

      const conditions = new ConditionBuilder()
        .setActiveModule(module ?? currentActiveModule)
        .setCompanyRoles(companyRole ? [companyRole] : [currentCompanyRole])
        .setAllRoles(allRoles)
        .setPermissions(permissions)
        .build();

      const canViewAdministrationPanel =
        conditions.permissions.haveAdministrationReadPermissions();

      const haveAdministrationEditPermissions =
        conditions.permissions.haveAdministrationEditPermissions();
      const haveEditRolesPermission =
        conditions.permissions.haveRoleEditPermissions();
      const haveReadPermissionPurchaseRequest =
        conditions.permissions.haveRequestsReadPermissions();
      const haveResponsesReadPermissions =
        conditions.permissions.haveResponsesReadPermissions();
      const haveRoutesReadPermissions =
        conditions.permissions.haveRoutesReadPermissions();
      const haveNeedsReadPermissions =
        conditions.permissions.haveNeedsReadPermissions();
      const haveNeedsEditPermissions =
        conditions.permissions.haveNeedsEditPermissions();
      const hasNeedsAcceptancePermissions =
        conditions.permissions.hasNeedsAcceptancePermissions();
      const haveCompaniesReadPermissions =
        conditions.permissions.haveCompaniesReadPermissions();
      const haveCompanyReadPermissions =
        conditions.permissions.haveCompanyReadPermissions();
      const haveEmployeesReadPermissions =
        conditions.permissions.haveEmployeesReadPermissions();
      const havePartnersReadPermissions =
        conditions.permissions.havePartnersReadPermissions();
      const haveFinanceReadPermissions =
        conditions.permissions.haveFinanceReadPermissions();
      const haveReadAccreditationPermissions =
        conditions.permissions.haveAccreditationReadPermissions();
      const hasWarehousesManagementPrivilege =
        conditions.permissions.hasWarehousesManagementPrivilege();
      const haveMarketplaceCustomerEditPermissions =
        conditions.permissions.haveMarketplaceCustomerEditPermissions();
      const haveMarketplaceSupplierReadPermissions =
        conditions.permissions.haveMarketplaceSupplierReadPermissions();
      const haveSettingsManagementPermissions =
        conditions.permissions.haveSettingsManagementPermissions();
      const haveCostAccountingDistributionPermissions =
        conditions.permissions.haveCostAccountingDistributionPermissions();
      const haveCostAccountingAnalyzePermissions =
        conditions.permissions.haveCostAccountingAnalyzePermissions();

      const isCustomer = conditions.companyRoles.includes(
        CompanyRoles.customer
      );
      const isSupplier = conditions.companyRoles.includes(
        CompanyRoles.supplier
      );

      const isETP = conditions.activeModule === ModuleName.ETP;
      const isMarketplace = conditions.activeModule === ModuleName.Marketplace;

      const resultSections: Sections = {
        [AppSection.Administration]: canViewAdministrationPanel,
        [AppSection.AdministrationCompanies]: canViewAdministrationPanel,
        [AppSection.AdministrationUsers]: canViewAdministrationPanel,
        [AppSection.AdministrationSettings]:
          canViewAdministrationPanel && haveAdministrationEditPermissions,
        [AppSection.AdministrationInboxes]:
          canViewAdministrationPanel && haveAdministrationEditPermissions,
        [AppSection.AdministrationModeration]:
          canViewAdministrationPanel && haveAdministrationEditPermissions,
        [AppSection.AllProcurements]: isETP,
        [AppSection.MyProcurements]:
          isETP && isCustomer && haveReadPermissionPurchaseRequest,
        [AppSection.MyProposals]:
          isETP && isSupplier && haveResponsesReadPermissions,
        [AppSection.AccreditationRequests]:
          isETP && isSupplier && haveReadAccreditationPermissions,
        [AppSection.PartnershipRequests]:
          isETP && isSupplier && havePartnersReadPermissions,
        [AppSection.Needs]:
          isETP &&
          isCustomer &&
          (haveNeedsReadPermissions ||
            haveNeedsEditPermissions ||
            hasNeedsAcceptancePermissions),
        [AppSection.SupplierManagement]: isETP && isCustomer,
        [AppSection.QualificationRequests]:
          isETP && isCustomer && havePartnersReadPermissions,
        [AppSection.QualifiedSuppliers]:
          isETP && isCustomer && havePartnersReadPermissions,
        [AppSection.PartnersCustomer]:
          isETP && isCustomer && havePartnersReadPermissions,
        [AppSection.Accreditation]:
          isETP && isCustomer && haveReadAccreditationPermissions,
        [AppSection.PartnersSupplier]:
          isETP &&
          isSupplier &&
          havePartnersReadPermissions &&
          haveCompaniesReadPermissions,
        [AppSection.Companies]: haveCompaniesReadPermissions,
        [AppSection.CompanyProfile]: haveCompaniesReadPermissions,
        [AppSection.MyCompany]:
          haveCompanyReadPermissions || haveEmployeesReadPermissions,
        [AppSection.CompanyEmployees]: haveEmployeesReadPermissions,
        [AppSection.CompanyRoleManagement]:
          (haveCompanyReadPermissions || haveEmployeesReadPermissions) &&
          haveEditRolesPermission,
        [AppSection.CompanyFinance]:
          isETP &&
          (haveCompanyReadPermissions || haveEmployeesReadPermissions) &&
          haveFinanceReadPermissions,
        [AppSection.Warehouses]:
          isETP &&
          (haveCompanyReadPermissions || haveEmployeesReadPermissions) &&
          isCustomer &&
          hasWarehousesManagementPrivilege,
        [AppSection.Routes]: isETP && isCustomer && haveRoutesReadPermissions,
        [AppSection.Goods]: isMarketplace,
        [AppSection.InboxOrders]:
          isMarketplace && haveMarketplaceSupplierReadPermissions,
        [AppSection.OutboxOrders]:
          isMarketplace && haveMarketplaceCustomerEditPermissions,
        [AppSection.Cart]:
          isMarketplace && haveMarketplaceCustomerEditPermissions,
        [AppSection.ProfileEdit]: true,
        [AppSection.Tasks]: false,
        [AppSection.MyOffice]: true,
        [AppSection.Messenger]: true,
        [AppSection.Tools]: true,
        [AppSection.Integrations]: true,
        [AppSection.Settings]: true,
        [AppSection.SettingsNotifications]: true,
        [AppSection.SettingsGeneral]: haveSettingsManagementPermissions,
        [AppSection.CostAccounting]: isCustomer,
        [AppSection.CostObjects]: isCustomer,
        [AppSection.CostsDistribution]:
          isCustomer && haveCostAccountingDistributionPermissions,
        [AppSection.CostAnalysis]:
          isCustomer && haveCostAccountingAnalyzePermissions
      };

      return resultSections;
    },
    [currentActiveModule, currentCompanyRole, permissions]
  );

  const sections = useMemo(() => getAllowedSections(), [getAllowedSections]);

  const getDifference = useCallback(
    (aSections: Sections, bSections: Sections) => {
      const aSectionsAllowedList = sectionsToAllowedSectionsList(aSections);
      const bSectionsAllowedList = sectionsToAllowedSectionsList(bSections);

      return difference(aSectionsAllowedList, bSectionsAllowedList);
    },
    []
  );

  return {
    sections,
    getAllowedSections,
    getDifference
  };
}
