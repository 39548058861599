import type { MyProcurementsFiltersState } from './myProcurementsFilter.types';
import { MyProcurementsFilterStatus } from './myProcurementsFilterStatus';

export const MY_PROCUREMENTS_FILTER_REDUCER = 'myProcurementsFilter';

export const INITIAL_FILTERS = {
  authorsId: [],
  assigneeUsersId: [],
  procurementStatuses: [],
  proposalStatuses: []
};

export const INITIAL_STATE: MyProcurementsFiltersState = {
  status: MyProcurementsFilterStatus.IDLE,
  haveSavedFilters: false,
  activeFilters: INITIAL_FILTERS,
  savedFilters: INITIAL_FILTERS
} as const;
